import * as React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography";
declare var __PATH_PREFIX__;

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  //if (location.pathname === rootPath) {
  if (true) {
    header = (
      <h1
        style={{
          ...scale(1.0),
          marginBottom: rhythm(1.0),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }

  const mainStyle:React.CSSProperties = {
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: rhythm(24),
    padding: `${rhythm(3 / 4)}`,
  };

  const navStyle:React.CSSProperties = {
    textAlign:'center',
    marginTop:`${rhythm(0)}`,
    marginBottom:`${rhythm(0)}`,
    borderBottom:`1px solid #e4e4e4`
  }

  const s:React.CSSProperties = {marginRight:'16px', boxShadow: `none`}

  return (
    <div style={{ marginLeft: `auto`, marginRight: `auto`, maxWidth: '100%' }}>
      <div style={navStyle}>
        <Link to="/" style={s}>
        Posts
        </Link>
        <Link to="/about" style={s}>
        About
        </Link>
        <Link to="https://games.deepstacker.com" style={s}>
        Games
        </Link>
      </div>
      <main style={mainStyle}>
        {children}
        <footer>
          <br/>
          © {new Date().getFullYear()}, Built with
            {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </main>
    </div>
  )
}

export default Layout
